import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
// import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as AOS from 'aos';
import * as datepicker from 'datepicker';
import * as initExitPopup from '../functions/initExitPopup';

import * as Lazy from 'jquery-lazy';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		// $map = document.getElementById('map'),
		$offersSlider = document.querySelectorAll('.promo-hld');

	if($offersSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, #menu-btn, .overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		$(".section--gallery").lightGallery({
			selector: '.lightgallery' 
		});

		$(".lightgallery-item").lightGallery({
			selector: 'this' 
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	$("img[data-src], div[data-src], figure[data-src], a[data-src]").Lazy({
		effect: "fadeIn",
        effectTime: 200,
        attribute: 'data-lazy',
        enableThrottle: true,
        throttle: 250,
        threshold: 200
	});

	window.setTimeout(function(){ 
		// First check, if localStorage is supported.
		if (window.localStorage) {
			// Get the expiration date of the previous popup.
			var cookies = localStorage.getItem( 'cookie' );

			if (cookies > new Date()) {
				return;
			}

			// Store the expiration date of the current popup in localStorage.
			var expires = new Date();
			expires = expires.setHours(expires.getHours() + 24);

			localStorage.setItem( 'cookie', expires );
		}

		$('#cookie-notice').show();

		$('#cookie-notice .icon-delete').on('click', function(){
			$('#cookie-notice').hide();
		});
	}, 0);

	$('#resform .from').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(da, obj) {
            var spl = da.split(/-/);
            var month = spl[1];
            
            month--;

            if(month < 10) {
                month = '0' + month;
            }
            $('#resform .qCiD').val(spl[2]);
            $('#resform .qCiMy').val(month + '' + spl[0]);

            $('.error--from').hide();
            $(this).removeClass('error-input');

            var min_date = new Date(da);
            $('#resform .to').datepicker('option', 'minDate', min_date);
        },
        minDate: new Date(),
    });

    initExitPopup.init();

    // $("#resform .from").datepicker("setDate", new Date());

    $('#resform .to').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(da, obj) {
            var spl = da.split(/-/);
            var month = spl[1];
            
            month--;

            if(month < 10) {
                month = '0' + month;
            }
            $('#resform .qCoD').val(spl[2]);
            $('#resform .qCoMy').val(month + '' + spl[0]);

            $('.error--to').hide();
            $(this).removeClass('error-input');
        }
    });

 //    var tomorrow = new Date();
	// tomorrow.setDate(tomorrow.getDate() + 1);

 //    $("#resform .to").datepicker("setDate", tomorrow);

    $("#hotels").change(function() {
	  var action = $("#hotels option:selected").attr('data-target'),
	  	  qSlH = $("#hotels option:selected").attr('data-qSlH');

	  $("#resform").attr("action", action);
	  $("#resform").find('input[name ="qSlH"]').val(qSlH);

		$('.error--hotel').hide();
   		$('#hotels').removeClass('error-input');
	});

	$('#resform').on('submit',function(){
		$('.error').hide(); 
		$('input, select').removeClass('error-input');

	    var actionAttr = $(this).attr('action'),
	       qCiMy = $(this).find('input[name ="qCiMy"]').val(),
	       qCoMy = $(this).find('input[name ="qCoMy"]').val();

	   if(actionAttr == ""){
	   	$('.error--hotel').show();
	   	$('#hotels').addClass('error-input');
	   } 
	   if(qCiMy == ""){
	   	$('.error--from').show();
	   	$('.from').addClass('error-input');
	   } 
	   if(qCoMy == ""){
	   	$('.error--to').show();
	   	$('.to').addClass('error-input');
	   }

	   if(actionAttr == "" || qCiMy == "" || qCoMy == ""){
	   	return false;
	   }
	});

	$('#overlay-menu').find('.menu-item-has-children a').on('click', function(e){
		if($(this).parent().hasClass('menu-item-has-children')) {
			e.preventDefault();
			e.stopPropagation();
		}

		$(this).parent().toggleClass('active');

		$(this).parent().find('.sub-menu').first().slideToggle();
	});

	// if($map) {
	// 	googleMap.init();
	// }

	if($('.expand-menu').length) {
		$('.expand-menu').on('click', '.bar', function(e){
			let $self = $(this);

			if($self.parent().parent().is('ul')) {
				e.stopPropagation();

				$self.toggleClass('active');

				$self.parent().children('ul').slideToggle();
			}
		});
	}

	// $('.best-price-guarantee').on('click', function(e){
	// 	e.stopPropagation();
	// 	e.preventDefault();

	// 	$('.best-price-hidden').slideToggle();
	// });

	// $('.best-price-hidden__close').on('click', function(e){
	// 	e.stopPropagation();
	// 	e.preventDefault();

	// 	$('.best-price-hidden').slideUp();
	// });

	$('.button--reservation').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$('.popup-hld--contact').addClass('js-show');
	});

	if($('.popup-hld--startpage')) {
		$('.popup-hld--startpage').addClass('js-show');
	}

	$('.no-click > a, .list a.first').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
	});

	$('.fill-form').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		
		$('.popup-hld--conference').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

});

AOS.init({
	disable: 'mobile',
	easing: 'ease',
	duration: 500
});

// $(window).on('load', function() {
	
// 	if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
// 		var mySkrollr = skrollr.init({
// 			edgeStrategy: 'set',
// 			smoothScrolling: false,
// 			forceHeight: false
// 		});
// 	}

// });